export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'Texas Twelve',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} Upper Hand Inc.',
  'client_theme.ThemeSidebar.subHeader': 'Welcome!',
  'client_theme.ThemeSidebar.mission':
    'Welcome to the Texas Twelve Baseball & Softball Academy!',
  'client_theme.ThemeSidebar.learn_more': 'Learn More',
  'containers.browseEvents.FixedScheduleCard.btn': 'Register',
  'user_management.shared.InviteDialog.manager': 'Director',
  'user_management.shared.InviteDialog.coach': 'Coach/GM',
  'containers.contacts.updateContact.UpdateContact.manager': 'Director',
  'containers.contacts.updateContact.UpdateContact.coach': 'Coach/GM',
};
